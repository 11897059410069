import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';

export const StyledCategoriesList = styled.ul`
  padding-left: 0px;
  padding-top: ${spacer(100)};
  list-style-type: none;
  display: flex;
  gap: ${spacer(200)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      padding-left: ${spacer(200)};
      padding-top: 0px;
    `
  )}
`;
