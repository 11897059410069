'use client';

import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';
import { Text, TextProps } from '../Text/Text';

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-backdrop bg-black/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  'fixed z-sheet gap-4 bg-background p-5 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    defaultVariants: {
      side: 'right',
    },
    variants: {
      side: {
        bottom: 'inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-[90vw] lg:w-[50vw] xl:w-[30vw] data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left overflow-y-auto scrollbar-hide max-h-screen',
        right:
          'inset-y-0 right-0 h-full w-[90vw] lg:w-[50vw] xl:w-[30vw] data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right overflow-y-auto scrollbar-hide max-h-screen',
        top: 'inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
      },
    },
  },
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetClose = ({
  className,
  icon = { name: 'times', styling: 'far' },
  iconClassName,
}: React.HTMLAttributes<HTMLDivElement> & {
  icon?: IconProps['icon'];
  iconClassName?: string;
}) => (
  <SheetPrimitive.Close
    className={cn(
      'rounded-full text-black focus-visible:ring-1 focus-visible:ring-primary-30 disabled:pointer-events-none',
      className,
    )}
  >
    <Icon icon={icon} className={cn('size-5 md:size-6', iconClassName)} />
    <span className="sr-only">Close</span>
  </SheetPrimitive.Close>
);
SheetClose.displayName = 'SheetClose';

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ children, className, side = 'right', ...props }, ref) => (
    <SheetPortal>
      <SheetOverlay />
      <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
        {children}
      </SheetPrimitive.Content>
    </SheetPortal>
  ),
);
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col gap-4 md:gap-6', className)} {...props} />
);
SheetHeader.displayName = 'SheetHeader';

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex gap-4 py-4', className)} {...props} />
);
SheetFooter.displayName = 'SheetFooter';

const SheetTitle = React.forwardRef<HTMLAnchorElement, TextProps>(({ type = 'h3', ...props }, ref) => (
  <Text ref={ref} type={type} {...props} />
));
SheetTitle.displayName = 'SheetTitle';

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
};
