import { ChangeEvent, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { removeWhiteSpaces } from 'utils/textUtil';
import FormGroup from '../FormGroup/FormGroup';
import { StyledFormGroupType, StyledFromGroupResponse } from '../FormGroup/FormGroup.styled';
import FormLabel from '../FormLabel/FormLabel';
import { StyledInput } from './FormGroupTextArea.styled';

interface FormGroupTextAreaProps {
  className?: string;
  disabled?: boolean;
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  label?: string | ReactNode;
  name: string;
  required?: boolean;
  showError?: boolean;
  validate?: () => void;
  value?: string;
}

const FormGroupTextArea = ({
  className,
  disabled,
  handleChange,
  id,
  label,
  name: inputName,
  required,
  showError = true,
  validate,
  value: inputValue,
}: FormGroupTextAreaProps) => (
  <Field name={inputName} type="textarea" validate={validate} value={inputValue}>
    {({ input, meta }) => {
      const { name, onBlur, onChange, onFocus, type, value } = input;
      const { active, dirty, error, initial, invalid, submitFailed, valid } = meta;

      const isActive = active ? 'active' : '';
      const isInitial = value && initial ? 'initial' : '';
      const isValid = submitFailed && value && valid ? 'valid' : '';
      const isInvalid = submitFailed && invalid ? 'invalid' : '';
      const isDirty = dirty ? 'dirty' : '';

      return (
        <FormGroup
          className={removeWhiteSpaces(`${className} ${isDirty} ${isActive} ${isInitial} ${isInvalid} ${isValid}`)}
        >
          <StyledFormGroupType className={`formGroup-type-${type}`}>
            <StyledInput
              className="formGroup-input"
              disabled={disabled}
              id={id || name}
              name={name}
              onBlur={(event) => onBlur(event)}
              onChange={(event) => {
                onChange(event);
                if (handleChange) {
                  handleChange(event);
                }
              }}
              onFocus={(event) => onFocus(event)}
              required={required}
              value={value}
            />

            {label && <FormLabel htmlFor={id || name}>{label}</FormLabel>}
          </StyledFormGroupType>
          {(submitFailed || dirty) && error && showError && (
            <StyledFromGroupResponse className="formGroup-response">{error}</StyledFromGroupResponse>
          )}
        </FormGroup>
      );
    }}
  </Field>
);

export default FormGroupTextArea;
