import { ReactNode } from 'react';

interface TableRowProps {
  markedRow?: boolean;
  size?: number;
  title?: string;
  values?: ReactNode[];
}

const TableRow = ({ markedRow, size = 4, title, values }: TableRowProps) => {
  const mergedClassName = markedRow ? 'marked' : '';

  const rowValues = () => {
    const elements = [];

    if (values?.length) {
      values.forEach((value, index) => {
        elements.push(
          <td key={`value_${index}`} className="row-value">
            {value}
          </td>,
        );
      });

      if (size && size >= 0) {
        const numberOfEmptyColumns = size - elements.length;
        if (numberOfEmptyColumns > 0) {
          for (let i = 0; i < numberOfEmptyColumns; i += 1) {
            elements.push(<td key={`empty_${i}`} className="row-value empty-column" />);
          }
        }
      }
    }

    return elements;
  };

  return (
    <tr className={mergedClassName}>
      {title && <th className="row-title">{title}</th>}
      {rowValues().map((value) => value)}
    </tr>
  );
};

export default TableRow;
