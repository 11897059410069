import styled from 'styled-components';
import { spacer, color, specific } from 'theme';
import Icon from '../../Icon/Icon';

export const StyledRatingStars = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0px ${spacer(50)};
`;

export const StyledRatingStarsEditable = styled(StyledRatingStars)`
  display: inline-flex;
`;

export const StyledRatingStarsRatingList = styled.ul`
  display: flex;
  gap: 2px;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`;

export const StyledRatingStarsRatingListItem = styled.li`
  position: relative;
  display: inline-flex;
`;

export const StyledRatingStarsRatingIconWrapper = styled.div`
  &:first-child {
    left: 0;
    overflow: hidden;
    position: absolute;
    text-overflow: clip;
    top: 0;
    width: 50%;
    z-index: 1;
  }
`;

interface StyledRatingStarsRatingIconProps {
  filled?: boolean;
}

export const StyledRatingStarsRatingIcon = styled(
  Icon
)<StyledRatingStarsRatingIconProps>`
  fill: ${({ filled }) =>
    filled
      ? specific('product', 'rating-stars-fill-color')
      : color('whisper-200')};
`;
