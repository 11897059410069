import styled, { css } from 'styled-components';

export interface StyledCheckListProps {
  hasIcons?: boolean;
}

export const StyledCheckList = styled.ul<StyledCheckListProps>`
  ${({ hasIcons }) =>
    hasIcons &&
    css`
      padding-left: 0px;
      list-style: none;
    `};
`;
