import { ReactNode } from 'react';
import { StyledCategoriesList } from './RatingOverview.styled';

interface RatingOverviewProps {
  children: ReactNode;
  className?: string;
}

const RatingOverview = ({ children, className }: RatingOverviewProps) => {
  const mergedClassName = className
    ? `rating-categories ${className}`
    : 'rating-categories';
  return (
    <div className={mergedClassName}>
      <StyledCategoriesList className="rating-categories-list">
        {children}
      </StyledCategoriesList>
    </div>
  );
};

export default RatingOverview;
