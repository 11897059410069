import styled, { css } from 'styled-components';
import { spacer } from 'theme';

export interface StyledCheckListItemProps {
  hasIcon?: boolean;
}

export const StyledCheckListItem = styled.li<StyledCheckListItemProps>`
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      display: flex;
      align-items: center;
      gap: ${spacer(75)};
    `};
`;
