import React, { HTMLAttributes, ReactNode } from 'react';
import Popup from '../Popup/Popup';

interface TableProps extends HTMLAttributes<HTMLTableElement> {
  children: ReactNode;
  marked?: boolean;
  popupMessage?: string;
  title?: string;
}

const Table = ({ children, marked, popupMessage, title, ...props }: TableProps) => (
  <div className={`table-wrap ${marked ? 'marked' : ''}`}>
    {title && (
      <div className="table-title">
        <div className="row-title">{title}</div>
        {popupMessage && <Popup className="popup" description={popupMessage} title={title} />}
      </div>
    )}

    <table {...props}>
      <tbody>{children}</tbody>
    </table>
  </div>
);

export default Table;
