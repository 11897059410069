import FormError from 'components/Form/FormError/FormError';
import FormGroupTextArea from 'components/Form/FormGroupTextArea/FormGroupTextArea';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { FeedbackForm } from 'types/CustomerFeedback';
import { Button } from 'ui/Button/Button';
import { getValidationSchema, validateForm } from 'utils/formUtil';
import { remarksValidation } from 'utils/validation';
import { BaseSchema } from 'yup';

const FORM_ID = 'customer_feedback_form';

const schemaObject: Record<keyof FeedbackForm, BaseSchema> = {
  remarks: remarksValidation(),
};

interface CustomerFeedbackFormProps {
  onSubmit: (values: FeedbackForm) => void;
}

const CustomerFeedbackForm = ({ onSubmit }: CustomerFeedbackFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form className={FORM_ID} id={FORM_ID} onSubmit={handleSubmit}>
          <FormError />

          <FormGroupTextArea
            id={`${FORM_ID}_remarks`}
            label={formatMessage({
              id: 'general_customer_feedback_modal_remarks',
            })}
            name="remarks"
          />

          <Button className="w-full" disabled={submitting} type="submit">
            {formatMessage({
              id: 'general_customer_feedback_modal_send_btn',
            })}
          </Button>
        </form>
      )}
      validate={validateForm(getValidationSchema(schemaObject))}
    />
  );
};

export default CustomerFeedbackForm;
