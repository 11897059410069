import { Icon } from 'constants/icon';

import { useIntl } from 'react-intl';
import { CheckListItem } from '..';
import Typography from '../../Typography/Typography';
import { StyledPrivacyBlockCheckList } from './PrivacyBlock.styled';

type PrivacyBlock = {
  icon: string;
  labelId: string;
};

interface PrivacyBlockProps {
  className?: string;
}

const PrivacyBlock = ({ className }: PrivacyBlockProps) => {
  const { formatMessage } = useIntl();

  const privacyBlocks: PrivacyBlock[] = [
    { labelId: 'privacy_info_block_invoicing', icon: 'file-invoice-dollar' },
    { labelId: 'privacy_info_block_security', icon: 'user' },
    { labelId: 'privacy_info_block_ads', icon: 'megaphone' },
  ];

  return (
    <div className={className}>
      <Typography tag="h4" type="h2">
        {formatMessage({ id: 'privacy_info_block_title' })}
      </Typography>
      <StyledPrivacyBlockCheckList hasIcons>
        {privacyBlocks.map(({ labelId, icon }) => (
          <CheckListItem
            key={labelId}
            color="primary-300"
            icon={icon as Icon}
            iconSize={150}
          >
            <Typography html={formatMessage({ id: labelId })} />
          </CheckListItem>
        ))}
      </StyledPrivacyBlockCheckList>
    </div>
  );
};

export default PrivacyBlock;
