import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { PopupLink } from 'types/Links';
import { ICON_NAMES, Icon as IconName } from '../../../constants/icon';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import Link from '../../Link/Link';
import CMSPageLink from '../../Router/Link/CMSPageLink/CMSPageLink';
import LocalizedLink from '../../Router/Link/LocalizedLink/LocalizedLink';
import Modal from '../Modal/Modal';
import { StyledPopupLink } from './Popup.styled';

type PopupProps = {
  className?: string;
  description?: string;
  descriptionNode?: ReactNode;
  flyOverOnMobile?: boolean;
  icon?: ReactNode;
  iconName?: IconName;
  link?: PopupLink;
  linkText?: string;
  openInNewTab?: boolean;
  title?: string;
  togglePopupLinkText?: string;
};

const Popup = ({
  className,
  description,
  descriptionNode,
  flyOverOnMobile = true,
  icon,
  iconName = ICON_NAMES.INFO_CIRCLE,
  link = {},
  linkText,
  openInNewTab = true,
  title,
  togglePopupLinkText,
}: PopupProps) => {
  const { formatMessage } = useIntl();

  const { name, page, params, query, url } = link;

  const [showPopup, setShowPopup] = useState(false);

  let popUpLink = null;
  let togglePopupLink;

  if (page) {
    if (linkText) {
      popUpLink = (
        <CMSPageLink page={page} newTab>
          <Button>{linkText}</Button>
        </CMSPageLink>
      );
    }
  } else if (name && linkText) {
    if (openInNewTab) {
      popUpLink = (
        <LocalizedLink
          name={name}
          options={{
            query: {
              ...query,
              ...params,
            },
          }}
          newTab
        >
          <Button>{linkText}</Button>
        </LocalizedLink>
      );
    } else {
      popUpLink = (
        <LocalizedLink
          name={name}
          options={{
            query: {
              ...query,
              ...params,
            },
          }}
        >
          <Button>{linkText}</Button>
        </LocalizedLink>
      );
    }
  } else if (url && linkText) {
    popUpLink = (
      <Link className="popup-link" newTab url={url}>
        <Button>{linkText}</Button>
      </Link>
    );
  }

  if (togglePopupLinkText) {
    togglePopupLink = (
      <StyledPopupLink
        aria-label={formatMessage({ id: 'popup_link_label' }, { text: togglePopupLinkText })}
        onClick={() => setShowPopup(!showPopup)}
      >
        {togglePopupLinkText}
        {icon}
      </StyledPopupLink>
    );
  }

  return (
    <>
      <div
        aria-label={formatMessage({ id: 'popup_wrapper_label' }, { text: togglePopupLinkText })}
        className={className}
        onClick={(e) => {
          e?.stopPropagation();
          setShowPopup(!showPopup);
        }}
        role="button"
        tabIndex={0}
      >
        {togglePopupLink}

        {!togglePopupLink && (
          <div className="flex gap-2">
            <Icon type="custom" className="question-mark-icon" name={iconName} size={125} />
          </div>
        )}
      </div>

      <Modal
        closeModal={() => {
          setShowPopup(!showPopup);
        }}
        flyOverOnMobile={flyOverOnMobile}
        show={showPopup}
        title={title}
      >
        {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
        {descriptionNode}
        {popUpLink}
      </Modal>
    </>
  );
};

export default Popup;
