import { StaticImage as StaticImageCode } from 'constants/media';
import { LayoutType, StaticImage as StaticImageType } from 'types/Image';
import { LAYOUT_TYPES } from '../../../constants/image';
import Image from '../../Image/Image';

interface StaticImageProps {
  alt?: string;
  className?: string;
  code?: StaticImageCode;
  height?: number;
  layout?: LayoutType;
  staticImages?: StaticImageType[];
  width?: number;
}

const StaticImage = ({
  alt,
  className,
  code,
  height,
  layout = LAYOUT_TYPES.FILL,
  staticImages = [],
  width,
  ...props
}: StaticImageProps) => {
  if (!(staticImages && staticImages.length > 0 && code)) {
    return null;
  }

  const imageSrc = staticImages.find((image) => image.code && image.code === code);

  if (imageSrc?.url) {
    return (
      <div className={`${className} static-image-wrap`}>
        <Image alt={alt || imageSrc.code} height={height} layout={layout} src={imageSrc.url} width={width} {...props} />
      </div>
    );
  }
  return null;
};

export default StaticImage;
