import { GenericIcon } from 'components/Icon/Icon';
import { Icon as IconType } from 'constants/icon';
import { Spacer } from 'constants/styling';
import { ReactNode } from 'react';
import { Color } from 'types/Color';
import { StyledCheckListItem, StyledCheckListItemProps } from './CheckListItem.styled';

interface CheckListItemProps extends StyledCheckListItemProps {
  children: ReactNode;
  className?: string;
  color?: Color;
  icon?: IconType;
  iconSize?: Spacer;
  isCustomIcon?: boolean;
}

const CheckListItem = ({
  children,
  className,
  color,
  icon,
  iconSize = 100,
  isCustomIcon = true,
}: CheckListItemProps) => {
  const iconProps = {
    className: 'checklist-icon',
    color,
    size: iconSize,
  };

  return (
    <StyledCheckListItem className={className} hasIcon={!!icon}>
      {icon && <GenericIcon {...iconProps} type={isCustomIcon ? 'custom' : 'fa'} name={icon} />}
      {children}
    </StyledCheckListItem>
  );
};

export default CheckListItem;
