import { ReactNode } from 'react';
import { Modal } from '..';

interface IFrameOverlayProps {
  children?: ReactNode;
  className?: string;
  iFrameUrl: string;
  show?: boolean;
  title: string;
  toggleOverlay: (event?: MouseEvent) => void;
}

const IFrameOverLay = ({ children, className, iFrameUrl, show, title, toggleOverlay }: IFrameOverlayProps) => (
  <Modal className={className} closeModal={toggleOverlay} show={!!show} title={title}>
    <>
      {iFrameUrl && (
        <iframe className="mt-auto h-full min-h-[75vh] w-full overflow-hidden" src={iFrameUrl} title="energyLabel" />
      )}
      {children}
    </>
  </Modal>
);

export default IFrameOverLay;
