import { ReactNode, useRef, useState } from 'react';
import { Icon } from 'ui/Icon/Icon';
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle } from 'ui/Sheet/Sheet';
import { cn } from 'utils/cn';

interface FlyOverProps {
  children: ReactNode;
  id?: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  side?: 'left' | 'right';
  title?: string;
  withCloseIcon?: boolean;
  withPrint?: boolean;
}

const FlyOver = ({
  children,
  id,
  onOpenChange,
  open,
  side = 'left',
  title,
  withCloseIcon = true,
  withPrint,
}: FlyOverProps) => {
  const [scrolled, setScrolled] = useState(false);

  const sheetContentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!sheetContentRef.current) return;
    setScrolled(sheetContentRef?.current?.scrollTop > 60);
  };

  const isHeaderVisible = !!title || !!withCloseIcon;

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent id={id} side={side} onScroll={handleScroll} ref={sheetContentRef}>
        {isHeaderVisible && (
          <SheetHeader
            className={cn(
              'fixed top-0 flex flex-row items-center justify-between',
              'z-sheet h-14 w-[90vw] bg-white px-5 lg:w-[50vw] xl:w-[30vw]',
              side === 'left' ? 'left-0' : '',
              side === 'right' ? 'right-0' : '',
              scrolled
                ? 'border-whisper-200 duration-250 border-b shadow-sm transition-all ease-in-out'
                : 'duration-250 border-b border-transparent transition-all ease-in-out',
            )}
          >
            {(title || withPrint) && (
              <SheetTitle className="flex items-center gap-4">
                {title}
                {withPrint && (
                  <button onClick={() => window.print()} className="">
                    <Icon icon={{ name: 'print', styling: 'fal' }} className="size-6 hover:text-primary-30" />
                  </button>
                )}
              </SheetTitle>
            )}

            {withCloseIcon && <SheetClose />}
          </SheetHeader>
        )}
        <div className={cn(isHeaderVisible ? 'mt-9' : '')}>{children}</div>
      </SheetContent>
    </Sheet>
  );
};

export default FlyOver;
