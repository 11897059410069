import React, { HTMLAttributes } from 'react';

interface RadioButtonWithDescriptionProps
  extends HTMLAttributes<HTMLLabelElement> {
  checked?: boolean;
  description?: string;
  disabled?: boolean;
  label: string;
  labelClassName?: string;
  name: string;
  warning?: string;
}

const RadioButtonWithDescription = ({
  name,
  checked = false,
  disabled = false,
  label,
  labelClassName,
  description,
  warning,
  ...props
}: RadioButtonWithDescriptionProps) => {
  const mergedLabelClassName = labelClassName
    ? `form-check-label radio-alt ${labelClassName}`
    : 'form-check-label radio-alt delivery-method-title';
  return (
    <label className={mergedLabelClassName} htmlFor={name} {...props}>
      <input
        checked={checked}
        className="form-check-input"
        disabled={disabled}
        name={name}
        type="radio"
      />
      <span className="radio" />
      <span className="radio-title-wrap">
        <span className="radio-title">{label}</span>
        {description && <span className="subtitle">{description}</span>}
        {warning && <span className="orange">{warning}</span>}
      </span>
    </label>
  );
};

export default RadioButtonWithDescription;
