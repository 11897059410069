import { LinkButton } from 'components/Button/ButtonInstances';
import ClientOnlyPortal from 'components/Portal/ClientOnlyPortal';
import { APP_CONTENT_ID } from 'constants/app';
import { MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { EnergyLabel as EnergyLabelType, ProductFiche } from 'types/Product';
import { Image } from 'ui/Image/Image';
import { cn } from 'utils/cn';
import { isPDF } from '../../../utils/urlUtil';
import IFrameOverlay from '../IFrameOverlay/IFrameOverlay';

export interface StyledStylingProps {
  color?: string; // hex codes for different energy label colors
  flipHorizontal?: boolean;
}

interface EnergyLabelProps extends StyledStylingProps {
  className?: string;
  energyLabel?: EnergyLabelType;
  productFiche?: ProductFiche;
}

const EnergyLabel = ({ className, energyLabel, productFiche }: EnergyLabelProps) => {
  const { formatMessage } = useIntl();

  const [showEnergyLabelOverlay, setShowEnergyLabelOverlay] = useState(false);
  const [showEnergyLabelFicheOverlay, setShowEnergyLabelFicheOverlay] = useState(false);

  if (!energyLabel?.icon?.url) {
    return null;
  }

  const energyLabelCode = energyLabel?.energyCode;
  const energyLabelUrl = energyLabel?.detailedEnergyLabel?.url || energyLabel?.productEnergySheet?.url;
  const energyLabelFicheUrl = productFiche?.url || productFiche?.media?.url;

  const isEnergyLabelPDF = isPDF(energyLabelUrl);

  const isProductFichePDF = isPDF(energyLabelFicheUrl);

  const toggleEnergyLabelOverlay = (event?: MouseEvent) => {
    event && event.preventDefault();
    if (energyLabelUrl) {
      if (showEnergyLabelFicheOverlay) {
        setShowEnergyLabelFicheOverlay(false);
      }
      setShowEnergyLabelOverlay(!showEnergyLabelOverlay);
    }
  };

  const toggleEnergyLabelFicheOverlay = () => {
    if (energyLabelFicheUrl) {
      if (showEnergyLabelOverlay) {
        setShowEnergyLabelOverlay(false);
      }
      setShowEnergyLabelFicheOverlay(!showEnergyLabelFicheOverlay);
    }
  };

  const iFrames = [
    {
      hasPdfExtension: isEnergyLabelPDF,
      show: showEnergyLabelOverlay,
      toggleFunction: toggleEnergyLabelOverlay,
      url: energyLabelUrl,
    },
    {
      hasPdfExtension: isProductFichePDF,
      show: showEnergyLabelFicheOverlay,
      toggleFunction: toggleEnergyLabelFicheOverlay,
      url: energyLabelFicheUrl,
    },
  ];

  return (
    <>
      {iFrames?.map(
        ({ hasPdfExtension, show, toggleFunction, url }, index) =>
          show &&
          url && (
            <ClientOnlyPortal key={index} selector={`#${APP_CONTENT_ID}`}>
              <IFrameOverlay
                key={index}
                className={`energy-label ${hasPdfExtension ? 'pdf' : ''}`}
                iFrameUrl={url}
                show={show}
                title={formatMessage({ id: 'general_energylabel_label_title' }, { code: energyLabelCode })}
                toggleOverlay={() => toggleFunction()}
              />
            </ClientOnlyPortal>
          ),
      )}

      <div className={cn('flex items-center gap-2', className)}>
        <button className="relative h-7 w-14 flex-shrink-0" onClick={toggleEnergyLabelOverlay} type="button">
          <Image
            src={energyLabel.icon.url}
            fill={true}
            useNext={true}
            className="object-contain"
            alt={formatMessage({ id: 'energy_label_new_label' })}
          />
        </button>

        {energyLabelFicheUrl && (
          <LinkButton onClick={toggleEnergyLabelFicheOverlay} textType="small">
            {formatMessage({ id: 'general_energylabel_label' })}
          </LinkButton>
        )}
      </div>
    </>
  );
};

export default EnergyLabel;
