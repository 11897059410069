import { Spacer } from 'constants/styling';
import React, { ReactNode, memo, useCallback, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { ICON_NAMES } from '../../../constants/icon';
import { generateRatingObject, getCurrentRating } from '../../../utils/ratingUtil';
import { isMobileDevice } from '../../../utils/screenUtils';
import Typography from '../../Typography/Typography';
import {
  StyledRatingStars,
  StyledRatingStarsEditable,
  StyledRatingStarsRatingIcon,
  StyledRatingStarsRatingIconWrapper,
  StyledRatingStarsRatingList,
  StyledRatingStarsRatingListItem,
} from './RatingStars.styled';

interface RatingStarsProps {
  children?: ReactNode;
  className?: string;
  currentRating?: number;
  editable?: boolean;
  iconSize?: Spacer;
  setRatingValues?: (selectedRating?: number, type?: string) => void;
  title?: string;
  type?: string;
  withBrackets?: boolean;
}

const RatingStars = ({
  children,
  className,
  currentRating,
  editable,
  iconSize = 100,
  setRatingValues,
  title,
  type,
  withBrackets,
}: RatingStarsProps) => {
  const [rating, setRating] = useState(currentRating);
  const [canHoover, setCanHoover] = useState(true);

  const ratingElementRef = useRef<HTMLDivElement>(null);

  const onMouseOver = (event: React.MouseEvent) => {
    if (editable && canHoover && !isMobileDevice() && ratingElementRef.current) {
      const elementWidth = ratingElementRef.current?.offsetWidth;
      const containerOffset = document.getElementsByClassName('container')[0].clientLeft;

      const cursorPos = event.pageX - ratingElementRef.current?.offsetLeft - containerOffset;

      setRating(getCurrentRating(cursorPos, 0, elementWidth));
    }
  };

  const onMouseLeave = () => {
    if (editable && canHoover && !isMobileDevice()) {
      setRating(0);
    }
  };

  const onClick = (event: React.MouseEvent) => {
    if (editable && editable && ratingElementRef.current) {
      const elementWidth = ratingElementRef.current.offsetWidth;
      const containerOffset = document.getElementsByClassName('container')[0].clientLeft;

      const cursorPos = event.pageX - ratingElementRef.current.offsetLeft - containerOffset;

      const selectedRating = getCurrentRating(cursorPos, 0, elementWidth);

      setRating(selectedRating);
      setCanHoover(!canHoover);
      setRatingValues?.(selectedRating, type);
    }
  };

  const ratingArr = useMemo(
    () => generateRatingObject(editable ? rating : currentRating),
    [editable, rating, currentRating],
  );

  const getChunk = useCallback(
    (arr: { value: number }[], size: number) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size)),
    [ratingArr],
  );

  const ratingItems = getChunk(ratingArr, 2);

  const Body = memo(() => (
    <>
      <StyledRatingStarsRatingList className="rating-list">
        {ratingItems?.map((ratingItem, index) => (
          <StyledRatingStarsRatingListItem key={`rating-item-${index}`} className="rating-list-item">
            {ratingItem.map((item, i) => (
              <StyledRatingStarsRatingIconWrapper key={`rating-value-${i}`} className="rating-icon-container">
                <StyledRatingStarsRatingIcon
                  type="custom"
                  filled={item.value === 1}
                  name={ICON_NAMES.STAR_FULL}
                  size={editable ? 125 : iconSize}
                />
              </StyledRatingStarsRatingIconWrapper>
            ))}
          </StyledRatingStarsRatingListItem>
        ))}
      </StyledRatingStarsRatingList>
      {children && (
        <Typography className="rating-count" color="whisper-400">
          {withBrackets ? `(${children})` : children}
        </Typography>
      )}
    </>
  ));

  Body.displayName = 'RatingStarsBody';

  if (editable) {
    return (
      <StyledRatingStarsEditable
        ref={ratingElementRef}
        aria-label={title}
        className={className}
        onClick={(event) => onClick(event)}
        onMouseLeave={onMouseLeave}
        onMouseOver={(event) => onMouseOver(event)}
        role="button"
        tabIndex={0}
      >
        <Body />
      </StyledRatingStarsEditable>
    );
  }

  return (
    <StyledRatingStars className={className}>
      <Body />
    </StyledRatingStars>
  );
};

export default memo(RatingStars, isEqual);
